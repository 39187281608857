import { render, staticRenderFns } from "./ShowRelease.vue?vue&type=template&id=7b94e48d&scoped=true&"
import script from "./ShowRelease.vue?vue&type=script&lang=js&"
export * from "./ShowRelease.vue?vue&type=script&lang=js&"
import style1 from "./ShowRelease.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b94e48d",
  null
  
)

export default component.exports